import { combineReducers } from 'redux'
// import { reducer as formReducer } from 'redux-form';
import BatchesReducer from './reducer_batches'
import DevicesReducer from './reducer_device'
import FirmwareReducer from './reducer_firmware'
import HardwareReducer from './reducer_hardware'
import SoftwareReducer from './reducer_software'
import AuthReducer from './reducer_auth'
import UsersReducer from './reducer_users'
import GroupsReducer from './reducer_groups'
import LogsReducer from './reducer_logs'
import LogsConfiguration from './reducer_configuration'
import MacAddress from './reducer_macAddress'
import Routes from './reducer_routes'
import DeviceAssociation from './reducer_device_association'
import Programmer from './reducer_programmer'
import Statistic from './reducer_statistic'
import Categories from './reducer_categories'
import CatConfigRelation from './reducer_cat_config_relation'
import Project from './reducer_projects'

const rootReducer = combineReducers({
  auth: AuthReducer,
  batches: BatchesReducer,
  configuration: LogsConfiguration,
  devices: DevicesReducer,
  firmwares: FirmwareReducer,
  groups: GroupsReducer,
  hardware: HardwareReducer,
  logs: LogsReducer,
  softwares: SoftwareReducer,
  users: UsersReducer,
  macAddress: MacAddress,
  routes: Routes,
  deviceAssociation: DeviceAssociation,
  programmer: Programmer,
  statistic: Statistic,
  categories: Categories,
  catConfigRelation: CatConfigRelation,
  project: Project,
})

export default rootReducer
