import axios from 'axios'
import { reactLocalStorage } from 'reactjs-localstorage'
// import { log } from 'util';

export const FETCH_ALL_HARDWARE = 'fetch_all_hardware'
export const FETCH_HARDWARE_PERMISSIONS = 'fetch_hardware_permissions'
export const CHANGE_HARDWARE_PERMISSIONS = 'change_hardware_permissions'
export const FETCH_TOKEN_STATUS = 'fetch_token_status'
export const FETCH_HARDWARE = 'fetch_hardware'
export const FETCH_HARDWARE_DASHBOARD = 'fetch_hardware_dashboard'
export const CREATE_HARDWARE = 'create_hardware'
export const CLONE_HARDWARE = 'clone_hardware'
export const EDIT_HARDWARE = 'edit_hardware'
export const FETCH_BATCHES = 'fetch_batches'
export const FETCH_BATCH = 'fetch_batche'
export const CREATE_BATCH = 'create_batch'
export const EDIT_BATCH = 'edit_batch'
export const FETCH_FIRMWARES = 'fetch_firmwares'
export const FETCH_FIRMWARE = 'fetch_firmware'
export const FETCH_FILE = 'fetch_file'
export const EDIT_FIRMWARE = 'edit_firmware'
export const FETCH_ALL_SOFTWARE = 'fetch_all_software'
export const FETCH_SOFTWARE = 'fetch_software'
export const DEPLOY_SOFTWARE = 'deploy_software'
export const DEPLOY_FIRMWARE = 'deploy_firmware'
export const FETCH_SOFTWARE_FILE = 'fetch_software_file'
export const EDIT_SOFTWARE = 'edit_software'
export const EDIT_DEVICE = 'edit_device'
export const FETCH_DEVICE = 'fetch_device'
export const FETCH_DEVICES = 'fetch_devices'
export const FETCH_DEVICE_LOGS = 'fetch_device_logs'
export const FETCH_DEVICES_BATCH_INFO = 'fetch_devices_batch_info'
export const FETCH_ALL_MACADDRESS = 'fetch_all_mac_address'
export const FETCH_MACADDRESS = 'fetch_mac_address'
export const CREATE_MACADDRESS = 'create_mac_address'
export const EDIT_MACADDRESS = 'edit_mac_address'
export const LOGIN_USER = 'login_user'
export const GET_USERS = 'get_users'
export const CREATE_USER = 'create_user'
export const DELETE_USER = 'delete_user'
export const UPDATE_USER = 'update_user'
export const DELETE_CONFIG = 'delete_config'
export const GET_ALL_USER_ACCESS = 'get_all_user_access'
export const GET_USERS_PROGRAMMED_DEVICES = 'get_users_programmed_devices'
export const CREATE_GROUP = 'create_group'
export const DELETE_GROUP = 'delete_group'
export const GET_GROUPS = 'get_groups'
export const GET_USERS_BY_GROUP = 'get_users_by_group'
export const REMOVE_USER_FROM_GROUP = 'remove_user_from_group'
export const ADD_USER_TO_GROUP = 'add_user_to_group'
export const CHANGE_HARDWARE = 'change_hardware'
export const CHANGE_MACADDRESS = 'change_mac_address'
export const CHANGE_BATCH = 'change_batch'
export const CHANGE_FIRMWARE = 'change_firmware'
export const GET_FIRMWARE_TYPE = 'get_firmware_type'
export const FETCH_FIRMWARE_DASHBOARD = 'fetch_firmware_dashboard'
export const CHANGE_SOFTWARE = 'change_software'
export const CHANGE_DEVICE = 'change_device'
export const GET_ALL_LOGS = 'get_all_logs'
export const DELETE_SOFTWARE_UPDATE_RANGE = 'delete_software_update_range'
export const FETCH_SOFTWARE_UPDATE_RANGE = 'fetch_software_update_range'
export const ADD_SOFTWARE_UPDATE_RANGE = 'add_software_update_range'
export const GET_SOFTWARE_TYPE = 'get_software_type'
export const GET_LOGS_TYPE = 'get_logs_type'
export const GET_LOGS_ORIGIN = 'get_logs_origin'
export const FETCH_ALL_CONFIGURATIONS = 'fetch_all_configuration'
export const CHANGE_CONFIGURATION = 'change_configuration'
export const FETCH_CONFIGURATION_FILE = 'fetch_configuration_file'
export const FETCH_CONFIGURATION = 'fetch_configuration'
export const FETCH_DEVICES_IN_RANGE = 'fetch_devices_in_range'
export const FETCH_ALL_DEVICES_BY_PRODUCT = 'fetch_all_devices_by_product'
export const FETCH_DEVICES_FIRMWARE_STATUS_IN_RANGE =
  'fetch_all_devices_firmware_status_in_range'
export const FETCH_ALL_DEVICES_LOGS = 'fetch_all_devices_logs'
export const CREATE_DEVICE = 'create_device'
export const CLONE_DEVICE = 'clone_device'
export const FETCH_ALL_ROUTES = 'fetch_all_routes'
export const DELETE_ROUTE = 'delete_route'
export const ADD_ROUTE = 'add_route'
export const DELETE_DEVICE = 'delete_device'
export const DELETE_LOGS = 'delete_logs'
export const DELETE_PERMISSION = 'delete_permission'
export const GET_CONFIG_ARCHITECTURE = 'get_config_architecture'
export const GET_FOLLOWUPDATE_BY_USER = 'get_followupdate_by_user'
export const POST_FOLLOWUPDATE = 'post_followupdate'
export const DELETE_FOLLOWUPDATE = 'delete_followupdate'
export const FETCH_ALL_DEVICE_ASSOCIATION = 'fetch_all_device_association'
export const FETCH_PROGRAMMER = 'fetch_programmer'
export const CHANGE_ROUTE = 'change_route'
export const FETCH_STATISTICS_DASHBOARD = 'fetch_statistics_dashboard'
export const GET_PUBLISH = 'get_publish'
export const GET_PUBLISH_BY_ID = 'get_publish_by_id'
export const GET_PUBLISH_BY_PUBLISHID = 'get_publish_by_publishId'
export const POST_PUBLISH = 'post_publish'
export const PATCH_PUBLISH = 'patch_publish'
export const DELETE_PUBLISH = 'delete_publish'
export const POST_CATEGORY = 'post_category'
export const PATCH_CATEGORY = 'patch_category'
export const DELETE_CATEGORY = 'delete_category'
export const GET_CATEGORY = 'get_category'
export const GET_CATEGORY_BY_ID = 'get_category_by_id'
export const GET_CATEGORY_DISTINCT = 'get_category_distinct'
export const GET_CATEGORY_CONFIG_RELATION = 'get_category_config_relation'
export const DELETE_CATEGORY_CONFIG_RELATION = 'delete_category_config_relation'
export const POST_CATEGORY_CONFIG_RELATION = 'post_category_config_relation'
export const GET_PROJECTS = 'get_projects'
export const POST_PROJECTS = 'post_projects'
export const PATCH_PROJECT = 'patch_projects'
export const DELETE_PROJECT = 'delete_project'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.Accept = 'application/json'
let API_URL = 'http://127.0.0.1:3000'
let API_PUB_URL = 'http://127.0.0.1:3000'
const API_DEV = 'http://api.ws.dev.displax.com'

if (
  process.env.REACT_APP_PORTAL_API_URL &&
  process.env.REACT_APP_PORTAL_API_URL !== ''
) {
  API_URL = process.env.REACT_APP_PORTAL_API_URL
  API_PUB_URL = process.env.REACT_APP_PORTAL_PUB_API_URL
}

/**
 * Auth
 */

export function getTokenStatus(callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/status`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_TOKEN_STATUS,
  }
}

export function getBasicToken(appName, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/basic/token${
      appName !== null ? `?appName=${appName}` : ''
    }`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_TOKEN_STATUS,
  }
}

export function loginUser({ username, password }, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/login`,
    data: {
      username,
      password,
    },
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: LOGIN_USER,
  }
}

/**
 * Routes
 */

export function changeProductionRoutesState({ active }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  const request = axios({
    headers,
    method: 'patch',
    url: `${API_URL}/routes/change/state`,
    data: { active },
  })

  return {
    payload: request,
    type: FETCH_ALL_ROUTES,
  }
}

export function fetchAllRoutes({ offset, include, limit, key, order, filter }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/routes`,
  })

  return {
    payload: request,
    type: FETCH_ALL_ROUTES,
  }
}

export function deleteRoute(routeId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/routes/${routeId}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_ROUTE,
  }
}

export function addRoute({ route, method, authentication, active }, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data: {
      route,
      method,
      authentication,
      active,
    },
    method: 'POST',
    url: `${API_URL}/routes`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: ADD_ROUTE,
  }
}

export function changeRoute(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/routes/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_ROUTE,
  }
}

/**
 * Programmer
 */

export function getProgrammerStatus(callback) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/deployment/authorization`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_PROGRAMMER,
  }
}

export function getDeploymentDashboard(callback) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/deployment/dashboard`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_PROGRAMMER,
  }
}

/**
 * Hardware
 */

export function getRevisionDistinct(brand) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      brand,
    },
    method: 'get',
    url: `${API_URL}/hardware/distinct/revision`,
  })

  return {
    payload: request,
    type: FETCH_ALL_HARDWARE,
  }
}

export function fetchAllHardware({
  offset,
  include,
  limit,
  key,
  order,
  filter,
  prefilter = '',
  startDate,
  endDate,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    prefilter,
    key,
    order,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/hardware`,
  })

  return {
    payload: request,
    type: FETCH_ALL_HARDWARE,
  }
}

export function fetchHardware(id, callback) {
  const request = axios
    .get(`${API_URL}/hardware/${id}`, {
      headers: { Authorization: reactLocalStorage.get('token') },
    })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_HARDWARE,
  }
}

export function getPermissions(route, id, callback) {
  const request = axios
    .get(`${API_URL}/${route}/${id}/permissions`, {
      headers: { Authorization: reactLocalStorage.get('token') },
    })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_HARDWARE_PERMISSIONS,
  }
}

export function changeHardware(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/hardware/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_HARDWARE,
  }
}

export function createHardware(
  {
    brand,
    codename,
    description,
    revision,
    product,
    metadata,
    platform,
    productId,
    labelName,
    labelTemplate,
    productLabel,
    iconName,
    productLine,
  },
  callback,
) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data: {
      brand,
      codename,
      description,
      revision,
      product,
      metadata,
      platform,
      productId,
      labelName,
      labelTemplate,
      productLabel,
      iconName,
      productLine,
    },
    method: 'POST',
    url: `${API_URL}/hardware`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: ADD_SOFTWARE_UPDATE_RANGE,
  }
}

export function cloneHardware(
  {
    brand,
    codename,
    description,
    revision,
    product,
    metadata,
    platform,
    productId,
    labelName,
    labelTemplate,
    productLabel,
    iconName,
    productLine,
  },
  token,
  callback,
) {
  labelName = labelName === null ? '' : labelName
  iconName = iconName === null ? '' : iconName
  productLabel = productLabel === null ? '' : productLabel

  const request = axios({
    headers: {
      Authorization: token,
      username: reactLocalStorage.get('username'),
    },
    data: {
      brand,
      codename,
      description,
      revision,
      product,
      metadata,
      platform,
      productId,
      labelName,
      labelTemplate,
      productLabel,
      iconName,
      productLine,
    },
    method: 'POST',
    url: `${API_DEV}/hardware/clone`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CLONE_HARDWARE,
  }
}

/**
 * MacAddress
 */

export function fetchAllMacAddress(offset, include, limit, key, order, filter) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      Range: `items=${offset}-${limit}`,
      filter,
      include,
      key,
      order,
    },
    method: 'GET',
    url: `${API_URL}/macaddress`,
  })

  return {
    payload: request,
    type: FETCH_ALL_MACADDRESS,
  }
}

export function createMacAddress({ macAddressPrefix }, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data: {
      macAddressPrefix: macAddressPrefix,
    },
    method: 'POST',
    url: `${API_URL}/macaddress`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CREATE_MACADDRESS,
  }
}

/**
 * Batches
 */

export function fetchAllBatches({
  offset,
  include,
  limit,
  key,
  order,
  filter,
  endDate,
  startDate,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
  }

  if (endDate !== null && endDate !== undefined) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null && startDate !== undefined) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/batches`,
  })

  return {
    payload: request,
    type: FETCH_BATCHES,
  }
}

export function fetchBatch(id) {
  const request = axios.get(`${API_URL}/batches/${id}`, {
    headers: { Authorization: reactLocalStorage.get('token') },
  })

  return {
    payload: request,
    type: FETCH_BATCH,
  }
}

export function changeBatch(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/batches/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_BATCH,
  }
}

/**
 * Firmwares
 */

export function fetchAllFirmwares({
  brand,
  type,
  revision,
  category,
  filter,
  offset,
  include,
  limit,
  key,
  order,
  endDate,
  startDate,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    brand,
    type,
    revision,
    category,
    Range: `items=${offset}-${limit}`,
    filter,
    include,
    key,
    order,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/firmware`,
  })

  return {
    payload: request,
    type: FETCH_FIRMWARES,
  }
}

export function fetchFirmwaresNavbar() {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/firmwares/navbar`,
  })

  return {
    payload: request,
    type: FETCH_FIRMWARE_DASHBOARD,
  }
}

export function fetchAllUnPublishedFirmwares({
  brand,
  type,
  revision,
  category,
  offset,
  include,
  limit,
  key,
  order,
  filter,
  endDate,
  startDate,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    brand,
    type,
    revision,
    category,
    Range: `items=${offset}-${limit}`,
    filter,
    include,
    key,
    order,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/firmware?published=false`,
  })

  return {
    payload: request,
    type: FETCH_FIRMWARES,
  }
}

export function fetchFirmwareFileLink(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/firmware/${id}/file`,
  }).then((data) => {
    callback(data)
  })

  return {
    payload: request,
    type: FETCH_FIRMWARE,
  }
}

export function fetchFile(url, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url,
  }).then((data) => {
    callback(data)
  })

  return {
    payload: request,
    type: FETCH_FILE,
  }
}

export function changeFirmware(id, data, callback) {
  if (data.categoryId === 'null') {
    data.categoryId = null
  }

  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/firmware/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_FIRMWARE,
  }
}

export function getFirmwareTypeDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/firmwares/types`,
  })

  return {
    payload: request,
    type: GET_FIRMWARE_TYPE,
  }
}

/**
 * Software
 */

export function fetchAllSoftwares(
  { offset, limit, key, order, filter, startDate, endDate },
  serchQuery,
) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/software${serchQuery}`,
  })

  return {
    payload: request,
    type: FETCH_ALL_SOFTWARE,
  }
}

export function getSoftwareUpdateRange() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/software/updaterange`,
  })

  return {
    payload: request,
    type: FETCH_SOFTWARE_UPDATE_RANGE,
  }
}

export function removeSoftwareUpdateRange(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/software/updaterange/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_SOFTWARE_UPDATE_RANGE,
  }
}

export function fetchAllUnPublishedSoftwares({
  offset,
  limit,
  key,
  order,
  filter,
}) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      Range: `items=${offset}-${limit}`,
      filter,
      key,
      order,
    },
    method: 'get',
    url: `${API_URL}/software?published=false`,
  })

  return {
    payload: request,
    type: FETCH_ALL_SOFTWARE,
  }
}

export function fetchSoftwareFileLink(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/software/${id}/file`,
  }).then((data) => {
    callback(data)
  })

  return {
    payload: request,
    type: FETCH_SOFTWARE_FILE,
  }
}

export function fetchDeviceLogs({ serial, offset, limit, key, order }) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      Range: `items=${offset}-${limit}`,
      key,
      order,
    },
    method: 'get',
    url: `${API_URL}/devices/${serial}/logs`,
  })

  return {
    payload: request,
    type: FETCH_DEVICE_LOGS,
  }
}

export function fetchSoftware({ id }) {
  const request = axios.get(`${API_URL}/software/${id}`, {
    headers: { Authorization: reactLocalStorage.get('token') },
  })

  return {
    payload: request,
    type: FETCH_SOFTWARE,
  }
}

export function addSoftwareUpdateRange(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data: {
      rangeMax: data.finalRange,
      rangeMin: data.initialRange,
      softwareName: data.softwareName,
      updateVersion: data.updateVersion,
    },
    method: 'POST',
    url: `${API_URL}/software/updaterange`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: ADD_SOFTWARE_UPDATE_RANGE,
  }
}

export function deploySoftware(
  { architecture, file, name, releaseNotes, version },
  softwareLink,
  softwareSize,
  callback,
) {
  const request = axios({
    data: {
      architecture,
      file,
      name,
      published: false,
      releaseNotes,
      softwareLink,
      softwareSize,
      version,
    },
    method: 'POST',
    url: `${API_PUB_URL}/software`,
  }).then((data) => {
    callback(data)
  })

  return {
    payload: request,
    type: DEPLOY_SOFTWARE,
  }
}

export function deploySoftwareUpload(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data,
    method: 'POST',
    url: `${API_PUB_URL}/software/upload`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DEPLOY_SOFTWARE,
  }
}

export function changeSoftware(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/software/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_SOFTWARE,
  }
}

export function getSoftwareTypeDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/softwares/types`,
  })

  return {
    payload: request,
    type: GET_SOFTWARE_TYPE,
  }
}

/**
 * Device
 */

export function deleteDevice(serial, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/device/${serial}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_DEVICE,
  }
}

export function getAllDevicesLogs() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/devices/logs`,
  })

  return {
    type: FETCH_ALL_DEVICES_LOGS,
    payload: request,
  }
}

export function fetchAllDevices({
  offset,
  limit,
  key,
  order,
  filter,
  prefilter = '',
  startDate,
  endDate,
  latest = '',
  category,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
    prefilter,
    category,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/devices${
      latest ? `?latestFirmwareUpdated=${latest}` : ''
    }`,
  })

  return {
    type: FETCH_DEVICES,
    payload: request,
  }
}

export function getAllDevicesByProduct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/devices?allDevicesByProduct=true`,
  })

  return {
    type: FETCH_ALL_DEVICES_BY_PRODUCT,
    payload: request,
  }
}

export function fetchAllDevicesInRange({ startDate, endDate }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/devices?newDevices=true`,
  })

  return {
    type: FETCH_DEVICES_IN_RANGE,
    payload: request,
  }
}

export function fetchDeviceFirmwareStatusInRange({ startDate, endDate }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/devices?devicesFirmwareStatus=true`,
  })

  return {
    type: FETCH_DEVICES_FIRMWARE_STATUS_IN_RANGE,
    payload: request,
  }
}

export function deployFirmwareUpload(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data,
    method: 'POST',
    url: `${API_PUB_URL}/firmware/category/upload`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DEPLOY_FIRMWARE,
  }
}

export function getDevicesBatchInfo(batchSerial, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/devices/batch/${batchSerial}/info`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    type: FETCH_DEVICES_BATCH_INFO,
    payload: request,
  }
}

/**
 * Users
 */

export function getAllUsers(followUpdate = null) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/users${
      followUpdate !== null ? `?followUpdate=${followUpdate}` : ''
    }`,
  })

  return {
    payload: request,
    type: GET_USERS,
  }
}

export function getAllUserProgrammedDevices(startDate, endDate) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/users?allDevicesProgrammedCount=true&startDate=${startDate}&endDate=${endDate}`,
  })

  return {
    payload: request,
    type: GET_USERS_PROGRAMMED_DEVICES,
  }
}

export function getAllUserAccess(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/users/${id}/permissions`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_ALL_USER_ACCESS,
  }
}

export function createUser(
  { username, name, password, description },
  callback,
) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/users`,
    data: {
      name,
      username,
      password,
      description,
    },
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CREATE_USER,
  }
}

export function deleteUser(userId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/users/${userId}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_USER,
  }
}

export function updateUser(userId, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/users/${userId}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: UPDATE_USER,
  }
}

export function changeDevice(id, data, callback) {
  if (data.categoryId === 'null') {
    data.categoryId = null
  }

  if (data.firmwareId === 'null') {
    data.firmwareId = null
  }

  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    // id = serial
    url: `${API_URL}/devices/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_DEVICE,
  }
}

export function createDevice(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/devices${
      data.serialPart !== '' ? `?serialPart=${data.serialPart}` : ''
    }${data.state !== '' ? `&state=${data.state}` : ''}${
      data.chipId !== '' ? `&chipId=${data.chipId}` : ''
    }`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CREATE_DEVICE,
  }
}

export function cloneDevice(data, token, callback) {
  switch (data.state) {
    case 'Active':
      data.state = 1
      break
    case 'Inactive':
      data.state = 2
      break
    case 'Scrap':
      data.state = 3
      break
    default:
      data.state = 2
  }

  const request = axios({
    headers: {
      Authorization: token,
      username: reactLocalStorage.get('username'),
    },
    method: 'post',
    url: `${API_DEV}/device/clone${
      data.serial !== '' ? `?serial=${data.serial}` : ''
    }${data.state !== '' ? `&state=${data.state}` : ''}${
      data.chipId !== '' ? `&chipId=${data.chipId}` : ''
    }${data.description !== '' ? `&description=${data.description}` : ''}`,
    data: data.batch,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CLONE_DEVICE,
  }
}

export function createDeviceBatch(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/device/batch${
      data.serial !== '' ? `?serial=${data.serial}` : ''
    }${data.state !== '' ? `&state=${data.state}` : ''}${
      data.chipId !== '' ? `&chipId=${data.chipId}` : ''
    }`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CREATE_DEVICE,
  }
}

/**
 * Groups
 */

export function getAllGroups() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/groups`,
  })

  return {
    payload: request,
    type: GET_GROUPS,
  }
}

export function getAllUsersByGroup(groupId) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/groups/${groupId}/users`,
  })

  return {
    payload: request,
    type: GET_USERS_BY_GROUP,
  }
}

// Function to get Users in group by id for user access
export function getAllByGroup(groupId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/groups/${groupId}/users`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_USERS_BY_GROUP,
  }
}

export function createGroup({ name, description }, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/groups`,
    data: {
      name,
      description,
    },
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CREATE_GROUP,
  }
}

export function addUserToGroup(groupId, userId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'post',
    url: `${API_URL}/groups/${groupId}/users/${userId}`,
    data: {},
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: ADD_USER_TO_GROUP,
  }
}

export function removeUserFromGroup(groupId, userId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/groups/${groupId}/users/${userId}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: REMOVE_USER_FROM_GROUP,
  }
}

export function deleteGroup(groupId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/groups/${groupId}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_GROUP,
  }
}

/**
 * Permissions
 */

export function changePermissions(route, id, userPermissions, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/${route}/${id}/permissions`,
    data: userPermissions,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_HARDWARE_PERMISSIONS,
  }
}

/**
 * Logs
 */

export function fetchAllLogs({
  offset,
  limit,
  key,
  order,
  filter,
  prefilter = '',
  serial = '',
  startDate,
  endDate,
  filterType = '',
  filterOrigin = '',
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
    prefilter,
  }

  if (endDate !== null && endDate !== undefined) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null && startDate !== undefined) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (filterType !== null && filterType !== undefined && filterType !== '') {
    headers = {
      filterType,
      ...headers,
    }
  }

  if (
    filterOrigin !== null &&
    filterOrigin !== undefined &&
    filterOrigin !== ''
  ) {
    headers = {
      filterOrigin,
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/logs${serial ? `?serial=${serial}` : ''}`,
  })

  return {
    payload: request,
    type: GET_ALL_LOGS,
  }
}

export function selectDistinctTypeByOrigin(log_origin) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      log_origin,
    },
    method: 'get',
    url: `${API_URL}/logs/distinct/type/byOrigin`,
  })

  return {
    payload: request,
    type: GET_LOGS_TYPE,
  }
}

export function getLogsTypeDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/logs/distinct/type`,
  })

  return {
    payload: request,
    type: GET_LOGS_TYPE,
  }
}

export function getLogsOriginDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/logs/distinct/origin`,
  })

  return {
    payload: request,
    type: GET_LOGS_ORIGIN,
  }
}

/**
 * Delete Logs By Id
 */

export function deleteLogsById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/logs/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_LOGS,
  }
}

/**
 * Delete All Logs
 */

export function deleteAllLogs(ids, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/logs/delete/bulk/${ids}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_LOGS,
  }
}

/**
 * Configurations
 */

export function fetchAllConfigurations({
  offset,
  limit,
  key,
  order,
  filter,
  endDate,
  startDate,
  architecture,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (architecture !== null) {
    headers = {
      architecture,
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/config`,
  })

  return {
    payload: request,
    type: FETCH_ALL_CONFIGURATIONS,
  }
}

export function fetchAllUnPublishedConfigurations({
  offset,
  limit,
  key,
  order,
  filter,
  endDate,
  startDate,
  architecture,
}) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
      Range: `items=${offset}-${limit}`,
      filter,
      key,
      order,
      endDate:
        endDate === undefined || endDate == null
          ? ''
          : `${endDate.format('YYYY-MM-DD hh:mm:ss A Z')}`,
      startDate:
        startDate === undefined || startDate == null
          ? ''
          : `${startDate.format('YYYY-MM-DD hh:mm:ss A Z')}`,
      architecture:
        architecture === undefined || architecture == null ? '' : architecture,
    },
    method: 'get',
    url: `${API_URL}/config?published=false`,
  })

  return {
    payload: request,
    type: FETCH_ALL_CONFIGURATIONS,
  }
}

export function fetchConfigurationFileLink(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/config/${id}/file`,
  }).then((data) => {
    callback(data)
  })

  return {
    payload: request,
    type: FETCH_CONFIGURATION_FILE,
  }
}

export function fetchConfiguration({ id }) {
  const request = axios.get(`${API_URL}/config/${id}`, {
    headers: { Authorization: reactLocalStorage.get('token') },
  })

  return {
    payload: request,
    type: FETCH_CONFIGURATION,
  }
}

export function deleteConfiguration(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/config/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_CONFIG,
  }
}

export function changeConfiguration(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/config/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_CONFIGURATION,
  }
}

export function deletePermission(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/permission/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_PERMISSION,
  }
}

export function changePermissionsBulk(type, id, userPermissions, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/permissions/bulk/type/${type}/id/${id}`,
    data: userPermissions,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: CHANGE_HARDWARE_PERMISSIONS,
  }
}

export function getPermissionsByDataIdAndType(id, type, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/permissions/id/${id}/type/${type}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: FETCH_HARDWARE_PERMISSIONS,
  }
}

export function getConfigArchitectureDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/configs/architecture`,
  })

  return {
    payload: request,
    type: GET_CONFIG_ARCHITECTURE,
  }
}

export function getFollowUpdateByUserId(userId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/followUpdate/${userId}/userId`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_FOLLOWUPDATE_BY_USER,
  }
}

export function postFollowUpdate(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'POST',
    url: `${API_URL}/followUpdate`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: POST_FOLLOWUPDATE,
  }
}

export function deleteFollowUpdateByUserID(userId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/followUpdate/${userId}/userId`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_FOLLOWUPDATE,
  }
}

/**
 * Dashboard
 */

export function fetchDashboard() {
  const request = axios.get(`${API_URL}/dashboard`, {
    headers: { Authorization: reactLocalStorage.get('token') },
  })

  return {
    payload: request,
    type: FETCH_HARDWARE_DASHBOARD,
  }
}

export function fetchStatisticsDashboard({ startDate, endDate }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
  }

  if (endDate !== null) {
    headers = {
      endDate: endDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  if (startDate !== null) {
    headers = {
      startDate: startDate.format('YYYY-MM-DD hh:mm:ss A Z'),
      ...headers,
    }
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/statistics`,
  })

  return {
    payload: request,
    type: FETCH_STATISTICS_DASHBOARD,
  }
}

/**
 * DeviceAssociation
 */

export function fetchDeviceAssociation({ offset, limit, key, order }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/device/association/all/parents`,
  })

  return {
    payload: request,
    type: FETCH_ALL_DEVICE_ASSOCIATION,
  }
}

export function fetchDeviceAssociationFilter({
  offset,
  limit,
  key,
  order,
  filter,
}) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    filter,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/device/association/all/parents`,
  })

  return {
    payload: request,
    type: FETCH_ALL_DEVICE_ASSOCIATION,
  }
}

export function fetchDeviceAssociationModal(serial) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    serial,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/device/association/modal`,
  })

  return {
    payload: request,
    type: FETCH_ALL_DEVICE_ASSOCIATION,
  }
}

/**
 * Publish
 */

export function getPublish(callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/publish`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_PUBLISH,
  }
}

export function getPublishById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/publish/${id}/id`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_PUBLISH_BY_ID,
  }
}

export function getPublishByPublishId(publishId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/publish/${publishId}/publishId`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_PUBLISH_BY_PUBLISHID,
  }
}

export function postPublish(publishId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    data: {
      publishId: publishId,
    },
    method: 'POST',
    url: `${API_URL}/publish`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: POST_PUBLISH,
  }
}

export function patchPublish(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/publish/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: PATCH_PUBLISH,
  }
}

export function deletePublishById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/publish/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_PUBLISH,
  }
}

export function deletePublishByAssociation(publishId, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/publish/${publishId}/publishId`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_PUBLISH,
  }
}

/**
 * Categories
 */

export function getCategories({ offset, limit, key, order }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/categories`,
  })

  return {
    payload: request,
    type: GET_CATEGORY,
  }
}

export function getCategoryById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/categories/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: GET_CATEGORY_BY_ID,
  }
}

export function postCategory(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'POST',
    url: `${API_URL}/categories`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: POST_CATEGORY,
  }
}

export function patchCategory(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/categories/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: PATCH_CATEGORY,
  }
}

export function deleteCategoryById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/categories/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_CATEGORY,
  }
}

export function getCategoriesDistinct() {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'get',
    url: `${API_URL}/categories/distinct`,
  })

  return {
    payload: request,
    type: GET_CATEGORY_DISTINCT,
  }
}

/**
 * CategoriesConfigRelation
 */

export function getCatConfigRelation({ offset, limit, key, order }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/cat/config/relation`,
  })

  return {
    payload: request,
    type: GET_CATEGORY_CONFIG_RELATION,
  }
}

export function deleteCatConfigRelationById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/cat/config/relation/${id}/id`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_CATEGORY_CONFIG_RELATION,
  }
}

export function postCategoryConfigRelation(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'POST',
    url: `${API_URL}/cat/config/relation`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: POST_CATEGORY_CONFIG_RELATION,
  }
}

/**
 * Projects
 */

export function getProjects({ offset, limit, key, order }) {
  let headers = {
    Authorization: reactLocalStorage.get('token'),
    Range: `items=${offset}-${limit}`,
    key,
    order,
  }

  const request = axios({
    headers,
    method: 'get',
    url: `${API_URL}/projects`,
  })

  return {
    payload: request,
    type: GET_PROJECTS,
  }
}

export function postProject(data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'POST',
    url: `${API_URL}/projects`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: POST_PROJECTS,
  }
}

export function patchProjectById(id, data, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'patch',
    url: `${API_URL}/project/${id}`,
    data,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: PATCH_PROJECT,
  }
}

export function deleteProjectById(id, callback) {
  const request = axios({
    headers: {
      Authorization: reactLocalStorage.get('token'),
    },
    method: 'delete',
    url: `${API_URL}/project/${id}`,
  })
    .then((data) => {
      callback(false, data)
    })
    .catch((data) => {
      callback(true, data)
    })

  return {
    payload: request,
    type: DELETE_PROJECT,
  }
}
